@import url('https://fonts.googleapis.com/css2?family=Inconsolata:wght@200..900&display=swap');

html, body, #root {
    height: 100%;
    margin: 0;
}

ul {
    margin: 0;
}

section {
    margin-bottom: 0.8rem;
}

@media print
{   
    body * {
        color: black !important;
        font-family: Helvetica !important;
    }

    main {
        overflow-y: visible !important;
    }

    section {
        page-break-inside: avoid;
    }

    p {
        font-size: smaller !important;
    }

    a {
        text-decoration: none !important;
    }

    hr {
        border-top: solid 1px #000 !important;
    }

    .no-print, .no-print *
    {
        display: none !important;
    }
}